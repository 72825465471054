import React from "react"
import "../MintPage.scss"

export const VerticalItem = props => {
  const { data, item_index } = props

  return (
    <div
      style={{ zIndex: item_index % 3 === 1 ? "1" : "0" }}
      className={`transition delay-300 ${
        item_index % 3 === 0
          ? "left-roll-out"
          : item_index % 3 === 2
          ? "right-roll-out"
          : " card"
      } `}
    >
      <div className="w-full flex flex-col items-center relative ">
        <div className="flex flex-col items-center relative">
          <img src="/mint_page/bg_item.svg" />
          <div className="absolute h-full">
            <div className="h-1/2 flex flex-col">
              <div className="tiny-nft-title text-purple-dark flex items-center text-center text-lg h-1/5 sm:text-2xl xl:text-4xl font-poppins-normal font-bold w-8/12 mx-auto">
                <h4 className="m-auto">{data.name}</h4>
              </div>
              <div className="flex justify-center items-center pt-1 w-1/2 m-auto ">
                <img src={data.image} className="m-auto"></img>
              </div>
            </div>
            <div className="h-1/2">
              <div className="text-purple-dark font-semibold font-poppins-normal p-3 text-xl text-center">
                {data.description}
              </div>
              <div className="font-poppins-normal font-bold text-2xl lg:text-3xl xl:text-4xl text-white text-center ">
                FOUND IN:
              </div>
            </div>
          </div>
        </div>
        <div className="flex font-bold text-2xl lg:text-3xl xl:text-4xl font-poppins-normal items-center justify-center mint-item-rarity ">
          <div className="font-bold text-purple-dark text-center text-2xl font-poppins-normal sm:text-xl md:text-3xl">
            RARITY 100%
          </div>
        </div>
      </div>
    </div>
  )
}

export const HorizontalItem = props => {
  const { data, item_index } = props
  return (
    <div className="min-w-44 w-10/12 flex items-center mb-10 min-h-52 text-purple-dark font-poppins-normal relative items-center">
      <img src="/mint_page/bg_horizontal.png" className="w-full" />
      <div className="w-1/2 justify-between flex flex-col items-center absolute left-0 top-0 bottom-0">
        <div className="relative h-full w-full">
          <div className="flex font-bold h-1/6 w-1/2 items-center justify-center mx-auto text-center text-purple-dark w-8/12 lg:w-7/12 z-10">
            <h4 className="nft-name text-center font-bold font-poppins-normal sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
              {data.name}
            </h4>
          </div>
          <div className="absolute bottom-0 flex items-center justify-center left-0 mx-auto right-0 top-0 w-3/5">
            <img
              src={data.image}
              className="m-auto horizontal-nft w-full"
            ></img>
          </div>
          <div className="absolute bottom-0 flex h-1/6 items-center justify-center left-0 right-0">
            <div className="nft-name font-bold font-poppins-normal sm:text-lg md:text-2xl lg:text-3xl xl:text-4xl">
              RARITY 100%
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 rounded-r-xl top-10 absolute right-0">
        <div className="nft-desc text-purple-dark font-semibold  p-3 sm:text-lg md:text-xl lg:text-2xl text-center">
          {data.description}
        </div>
        <div className=" font-bold font-poppins-normal sm:text-xl md:text-2xl lg:text-4xl text-white text-center ">
          FOUND IN:
        </div>
      </div>
    </div>
  )
}

export const TinyItem = props => {
  const { data, item_index } = props
  return (
    <div className="w-8/12 flex items-center mb-10 min-h-52 text-purple-dark font-poppins-normal relative items-center">
      <div className="w-full flex flex-col items-center">
        <div className="flex flex-col items-center relative">
          <img src="/mint_page/bg_item.svg" />
          <div className="absolute h-full">
            <div className="h-1/2 flex flex-col">
              <div className="tiny-nft-title text-purple-dark flex items-center text-center text-lg h-1/5 sm:text-2xl xl:text-4xl font-poppins-normal font-bold w-8/12 mx-auto">
                <h4 className="m-auto">{data.name}</h4>
              </div>
              <div className="flex justify-center items-center pt-1 w-1/2 m-auto ">
                <img src={data.image} className="m-auto"></img>
              </div>
            </div>
            <div className="h-1/2 tiny-nft-desc overflow-y-scroll">
              <div className="text-purple-dark font-semibold font-poppins-normal p-3 text-center">
                {data.description}
              </div>
              <div className="font-poppins-normal font-bold text-md text-white text-center ">
                FOUND IN:
              </div>
            </div>
          </div>
        </div>
        <div className="bg-cyan-default h-8 rounded-t-sm  rounded-b-xl w-8/12 flex font-bold font-poppins-normal items-center justify-center md:text-4xl  sm:text-xl text-xs">
          <div className="font-bold text-purple-dark text-center font-poppins-normal">
            RARITY 100%
          </div>
        </div>
      </div>
    </div>
  )
}

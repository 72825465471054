import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Guide from "@components/partials/GuidePage"

const GuidePage = () => (
    <Layout
        title="Mark McKenna's Heroes & Villains NFT"
        contentClassName="GuidePageContent px-0"
        type="guid"
    >
        <Guide/>
    </Layout>
)

export default GuidePage

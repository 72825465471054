import React, { useState } from 'react'

const MintFilterTab = (props) => {
  const { data, active, setActive } = props;
  const handleTabClick = () => {
    setActive(data.filter);
  }
  return (
    <>
        <div className={`flex ${active===data.filter?"font-poppins-normal border-white border-b-4":""} rounded-sm cursor-pointer mt-2 gap-2`} onClick={handleTabClick}>
          <div className="pb-1 items-center gap-2 flex">
            <div>
              <img src={`${active===data.filter?data.active:data.default}` } className="w-6 h-6 md:w-8 md:h-8"/>
            </div>
            <h4 className={`text-xl md:text-2xl lg:text-3xl opacity-70 text-white  ${active===data.filter?"opacity-100":""} `}>{data.title}</h4>
          </div>
          <div style={{background: '#FFFFFF 0% 0% no-repeat padding-box', borderRadius: '3px', opacity: 1}}></div>
        </div>
    </>
  )
}

export default MintFilterTab

export const mintFilterData = [
  {
    title:"RACES",
    filter:"race",
    default:"/mint_page/ic_race_default.png",
    active:"/mint_page/ic_race_active.png"
  },
  {
    title:"WEAPONS",
    filter:"weapon",
    default:"/mint_page/ic_weapon_default.png",
    active:"/mint_page/ic_weapon_active.png"
  },
  {
    title:"ARTIFACTS",
    filter:"artifact",
    default:"/mint_page/ic_artifact_default.png",
    active:"/mint_page/ic_artifact_active.png",
  },
  {
    title:"POWERS",
    filter:"power",
    default:"/mint_page/ic_power_default.png",
    active:"/mint_page/ic_power_active.png",
  },
  {
    title:"LOCATIONS",
    filter:"location",
    default:"/mint_page/ic_location_default.png",
    active:"/mint_page/ic_location_active.png"
  },
]

import { guideData } from "@util/hvoriginsData"
import React, { useEffect, useState } from "react"
import { mintFilterData } from "./filter"

import { HorizontalItem, TinyItem, VerticalItem } from "./mintItem"
import "./MintPage.scss"
import MintFilterTab from "./minttab"

const Guide = () => {
  const [filter, setFilter] = useState("race")
  let isMounted
  const [screenmode, setScreenMode] = useState("")
  const checkScreenMode = () => {
    if (isMounted) {
      setScreenMode(
        window.innerWidth >= 960
          ? "large"
          : window.innerWidth >= 530
            ? "small"
            : "tiny"
      )
    }
  }

  useEffect(() => {
    isMounted = true
    checkScreenMode()
    window.addEventListener("resize", checkScreenMode)
    return () => {
      isMounted = false
      window.removeEventListener("resize", checkScreenMode)
    }
  }, [])

  return (
    <div className="mint-container h-screen overflow-y-scroll pb-16">
      <div className="flex flex-wrap justify-around mt-16 lg:px-8 md:px-8 sm:px-8 gap-5">
        {screenmode === "large" && (
          <div className="border-2 border-purple-light rounded-md px-8 py-1 gap-8 flex">
            <div className="flex cursor-pointer items-center justify-center p-1 cursor-pointer">
              <a href="/">
                <img
                  src="/mint_page/ico_home.svg"
                  className="w-6 h-6 md:w-8 md:h-8"
                />
              </a>
            </div>
            <div className="flex cursor-pointer items-center justify-center p-1 cursor-pointer">
              <a href="/mynfts">
                <img
                  src="/mint_page/ico_wallet.svg"
                  className="w-5 h-5 md:w-7 md:h-7"
                />
              </a>
            </div>
          </div>
        )}
        {mintFilterData.map((data, idx) => (
          <MintFilterTab
            data={data}
            active={filter}
            setActive={setFilter}
            key={idx}
          />
        ))}
        {screenmode !== "large" && (
          <div className="border-2 border-purple-light rounded-md px-5 py-1 gap-5 flex">
            <div className="flex cursor-pointer items-center justify-center p-1">
              <a href="/">
                <img
                  src="/mint_page/ico_home.svg"
                  className="w-6 h-6 md:w-8 md:h-8"
                />
              </a>
            </div>
            <div className="flex cursor-pointer items-center justify-center p-1">
              <a href="/mynfts">
                <img
                  src="/mint_page/ico_wallet.svg"
                  className="w-5 h-5 md:w-7 md:h-7"
                />
              </a>
            </div>
          </div>
        )}
      </div>
      <div
        className={`grid ${screenmode == "large" ? "grid-cols-3" : "grid-cols-1"
          } mt-28 justify-center justify-items-center items-center lg:px-8 md:px-8 sm:px-8 gap-5`}
      >
        {screenmode === "large" &&
          guideData.map(
            (data, idx) =>
              filter === data.type && (
                <VerticalItem data={data} item_index={idx} key={idx} />
              )
          )}

        {screenmode === "small" &&
          guideData.map(
            (data, idx) =>
              filter === data.type && (
                <HorizontalItem data={data} item_index={idx} key={idx} />
              )
          )}
        {screenmode === "tiny" &&
          guideData.map(
            (data, idx) =>
              filter === data.type && (
                <TinyItem data={data} item_index={idx} key={idx} />
              )
          )}
      </div>
    </div>
  )
}

export default Guide
